import React, { Suspense, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { Chrono } from 'react-chrono';
import DateService from '../../service/DateService';

interface Props {
    infos: any[];
}

const mapInfosToRows = (infos: any[]) => {
    return infos.map((info) => {
        const date = DateService.toDate(info.timestamp);
        if (info.state != undefined) {
            return {
                title: date,
                cardTitle: 'state',
                cardSubtitle: info.state
            };
        } else {
            const subtitle = `lat: ${info.latitude} / lon: ${info.longitude}`;
            return {
                title: date,
                cardTitle: info.address ? info.address : 'Click to view',
                url: `https://www.google.com/maps?q=${info.latitude},${info.longitude}`,
                cardSubtitle: subtitle
            };
        }
    });
};

function ClassRoute(props: Props) {
    const rows = mapInfosToRows(props.infos);
    return (
        <div className="col-md-6" style={{ width: '100%' }}>
            <Chrono
                items={rows}
                mode="VERTICAL_ALTERNATING"
                cardHeight={100}
                theme={{
                    primary: 'gray',
                    secondary: 'black',
                    cardBgColor: 'black',
                    titleColor: 'white',
                    titleColorActive: 'gray',
                    cardSubtitleColor: 'white'
                }}
            />
        </div>
    );
}

export default ClassRoute;
