import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import UseAuth from '../shared/UseAuth';
import QueryStringService from '../service/QueryStringService';
import SessionService from '../service/SessionService';
import Response from '../model/Response';
import ClassInformation from './class/ClassInformation';
import ClassRoute from './class/ClassRoute';

function PageView() {
    document.title = 'View - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<any>();

    useLayoutEffect(() => {
        //if (!authenticated) {
        //    window.location.replace('/');
        //}
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        // @ts-ignore
        //setDefaultGroupName(tokenParsed?.sub);
        //console.debug('Default group: ' + defaultGroupName);
        let id = QueryStringService.getString('id');
        if (id == null) {
            window.location.replace('/');
        }
        var query = process.env.REACT_APP_API_URL + '/v1/device/view?id=' + id;
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);
        //console.debug('Token : ' + SessionService.get(SessionService.token));

        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    console.debug('data:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
                //alert(Response.i18n['error-unknown']);
            });
    };

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-1"></div>
                <div className="col-md-4">{response && <ClassInformation device={response} />}</div>
                <div className="col-md-6">{response && <ClassRoute infos={response.infos} />}</div>
                <div className="col-md-1"></div>
            </div>
        </Suspense>
    );
}

export default PageView;
