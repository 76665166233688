import React, { Suspense, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../translations/Translator';
import UseAuth from '../shared/UseAuth';

// @ts-ignore
function PageWelcome() {
    document.title = 'Welcome - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '/device');
    const login = () => {
        // this could be an on-click event handler
        if (authenticated) {
            window.location.replace('/device');
        } else {
            keycloak.login();
        }
    };

    useEffect(() => {
        if (keycloak.authenticated) {
            window.location.replace('/device');
        }
    }, []);

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="navbar-brand" href="/">
                        &nbsp;&nbsp;&nbsp;
                        <img src="https://ik.imagekit.io/mufleet/ui/icons/48.png" alt="48.png" />
                    </a>
                    <a className="navbar-brand" href="/">
                        <h1 className="fs-1">{process.env.REACT_APP_NAME}</h1>
                    </a>
                </nav>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-5 align-self-center">
                        <div>
                            <img src="https://ik.imagekit.io/mufleet/ui/login.jpeg" width="100%" height="auto"></img>
                        </div>
                    </div>
                    <div className="col-md-3 align-self-center">
                        <h4>Welcome to the mufleet app</h4>
                        <br />
                        <h6>Track your fleet with accuracy</h6>
                        <br />
                        <button type="button" className="btn btn-success btn-lg" onClick={login}>
                            Login / Register
                        </button>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageWelcome;
