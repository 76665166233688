import React, { useState } from 'react';
import SessionService from '../../service/SessionService';

function TableDeviceManagement(props: { data: { [x: string]: string }; group: string; id: string }) {
    const captureValue = async (event: { target: { id: string; value: string } }) => {
        if(event.target.id == null || event.target.id == '' || event.target.id == undefined){
            return;
        }
        var query = process.env.REACT_APP_API_URL + '/v1/metadata?id=' + props.id + '&type=DEVICE&base64key=' + btoa(event.target.id) + '=&base64value=' + btoa(event.target.value);
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res);
                }
            })
            .catch((error) => {
                alert('An error happened, please try again');
            });
    };
    // @ts-ignore
    // @ts-ignore
    if (props.data.length === 0) {
        return (
            <div>
                <div className='row'>
                     <p>You do not have any device code yet, start by downloading our driver app 😉</p>
                </div>
                <br />
                <div className='row'>
                    <a href='https://play.google.com/store/apps/details?id=com.mufleet' target='_blank'
                       rel='noreferrer'>
                        <img width='100%' src='https://ik.imagekit.io/mufleet/ui/img/google_play_64.png' alt='google play' />
                    </a>
                </div>
                <br />
                <div className='row'>
                     <p>Once the app is installed on your device, you will get a code.</p>
                </div>
                <br />
                <div className='row'>
                     <p>Go to <a href="https://app.mufleet.com/device" target={"_blank"}>https://app.mufleet.com/device</a> and add the code.</p>
                </div>
            </div>
        );
    } else {
        return (
            <table className="table table-dark table-striped">
            <thead>
                <tr>
                    <th className="col-md-6">Name</th>
                    <th className="col-md-6">Value</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(props.data).map((key, index) => (
                    <tr>
                        <td>
                            <p className="fs-5">{key}</p>
                        </td>
                        <td>
                            <input className="form-control" type={'text'} id={key} defaultValue={props.data[key]}
                                   placeholder="Add a property value here" onBlur={captureValue}></input>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        );
    }
}

export default TableDeviceManagement;
