import React from 'react';
import UserDTO from '../../archive/model/UserDTO';
import RowUser from '../../archive/RowUser';
import Translator from '../../translations/Translator';
import RowFleet from '../row/RowFleet';

function TableFleet(props: { data: Array<{}> }) {
    // @ts-ignore
    return (
        <table className="table table-dark table-sm table-striped table-responsive-md">
            <thead>
                <tr>
                    <th scope="col">vehicle</th>
                    <th scope="col">status</th>
                    <th scope="col">address</th>
                    <th scope="col">latitude</th>
                    <th scope="col">longitude</th>
                    <th scope="col">timestamp</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowFleet row={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableFleet;
