export class Shipment {
    id: string;
    createdTime: string;
    deletedTime: string;
    groupId: string;
    readableId: string;
    status: string;
    deviceId: string;
    pickupTimeMin: string;
    pickupTimeMax: string;
    destinationTimeMin: string;
    destinationTimeMax: string;
    pickup: string;
    destination: string;
    pickupLat: number;
    pickupLon: number;
    destinationLat: number;
    destinationLon: number;

    constructor(data: any) {
        this.id = data.id || null;
        this.createdTime = data.createdTime || null;
        this.deletedTime = data.deletedTime || null;
        this.groupId = data.groupId || null;
        this.readableId = data.readableId || null;
        this.status = data.status || null;
        this.deviceId = data.deviceId || null;
        this.pickupTimeMin = data.pickupTimeMin || null;
        this.pickupTimeMax = data.pickupTimeMax || null;
        this.destinationTimeMin = data.destinationTimeMin || null;
        this.destinationTimeMax = data.destinationTimeMax || null;
        this.pickup = data.pickup || null;
        this.destination = data.destination || null;
        this.pickupLat = data.pickupLat || 0;
        this.pickupLon = data.pickupLon || 0;
        this.destinationLat = data.destinationLat || 0;
        this.destinationLon = data.destinationLon || 0;
    }

}