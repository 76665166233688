import React, { Suspense, useLayoutEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import Response from '../model/Response';
import QueryStringService from '../service/QueryStringService';
import UseAuth from '../shared/UseAuth';
import ClassFleetMap from './class/ClassFleetMap';
import SessionService from '../service/SessionService';
import ClassFilters from './class/ClassFilters';
import Pagination from './pagination/Pagination';
import TableFleet from './table/TableFleet';

// @ts-ignore
function PageFleet() {
    document.title = 'Fleet - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<any>();

    useLayoutEffect(() => {
        //if (!authenticated) {
        //    window.location.replace('/');
        //}
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = async () => {
        // @ts-ignore
        //setDefaultGroupName(tokenParsed?.sub);
        //console.debug('Default group: ' + defaultGroupName);
        let page = QueryStringService.getString('page');
        if (page == null) {
            page = '0';
        }
        let filter = QueryStringService.getString('filter');
        let key = null;
        let value = null;
        if (filter != null) {
            let parts = filter.split('=');
            key = parts[0];
            value = parts[1];
        }
        let group = QueryStringService.getString('group');
        var query = process.env.REACT_APP_API_URL + '/v1/fleet?page=' + page;
        if (key != null && value != null) {
            query = query + '&base64key=' + window.btoa(key) + '&base64value=' + window.btoa(value);
        }
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }

        console.debug(query);
        //console.debug('Token : ' + SessionService.get(SessionService.token));

        await fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    console.debug('data:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
                //alert(Response.i18n['error-unknown']);
            });
    };

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-12">
                    <div className="mb-3 row">{response && <ClassFleetMap group={response.currentGroup} mapConfig={response.mapConfig} mapContent={response.fleetCoordinates} />}</div>
                    <div className="mb-3 row"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">{response && <ClassFilters keys={response.keys} />}</div>
                <div className="col-md-6">{response && <Pagination data={response.pages} link={'page'} />}</div>
            </div>
            <br />
            <div className="mb-3 row">{response && <TableFleet data={response.pages.content} />}</div>
        </Suspense>
    );
}

export default PageFleet;
