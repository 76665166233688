import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Translator from '../../translations/Translator';
import { io } from 'socket.io-client';
import QueryStringService from '../../service/QueryStringService';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { isBooleanObject } from 'util/types';

// @ts-ignore
function ClassFilters(props: { keys }) {
    const [filterName, setFilterName] = useState('');
    const [filterValue, setFilterValue] = useState('');

    const handleFilterName = (event: { target: { value: any } }) => {
        setFilterName(event.target.value);
    };
    const handleFilterValue = (event: { target: { value: any } }) => {
        setFilterValue(event.target.value);
    };

    // @ts-ignore
    const filterList = props.keys.map((item: string) => {
        console.debug('key ' + item);
        return <option value={item}>{item}</option>;
    });

    useEffect(() => {
        let filter = QueryStringService.getString('filter');
        // @ts-ignore
        if (filter != null) {
            let parts = filter.split('=');
            if (parts[0] != '' && parts[1] != '') {
                setFilterName(parts[0]);
                setFilterValue(parts[1]);
            }
        }
    }, []);

    function search() {
        if (filterName.includes('=')) {
            QueryStringService.goTo('filter', filterName);
        } else {
            QueryStringService.goTo('filter', filterName + '=' + filterValue);
        }
    }

    return (
        <div className="row">
            <div className="col-md-3">
                <select className="form-select" value={filterName} onChange={handleFilterName}>
                    <option value="">-Pick a filter-</option>
                    <option value="id">-id-</option>
                    <option value="status=TRACKING">-tracking-</option>
                    <option value="status=NOT_TRACKING">-not tracking-</option>
                    <option value="address">-address-</option>
                    {filterList}
                </select>
            </div>
            <div className="col-md-6">
                <input className="form-control" value={filterValue} onChange={handleFilterValue} placeholder="Add a filter value here" />
            </div>
            <div className="col-md-3">
                <button className="btn btn-secondary" onClick={search}>
                    Search
                </button>
            </div>
        </div>
    );
}

export default ClassFilters;
