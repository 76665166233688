import React, { useState, useEffect, useRef } from 'react';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import SessionService from '../service/SessionService';

const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL!,
    realm: process.env.REACT_APP_KEYCLOAK_REALM!,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT!
});

const UseAuth = (init: boolean, redirect: string) => {
    const isRun = useRef(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (isRun.current) return;

        isRun.current = true;

        if (init) {
            keycloak
                .init({ onLoad: 'check-sso' })
                .then((authenticated) => {
                    setAuthenticated(authenticated);
                    if (authenticated) {
                        SessionService.set(SessionService.token, keycloak.token);
                        const tokenParsed: any = keycloak.tokenParsed;
                        // @ts-ignore
                        SessionService.set(SessionService.username, tokenParsed.preferred_username);
                        SessionService.set(SessionService.groups, tokenParsed.groups);
                        // @ts-ignore
                        //alert('Preferred Username: '+ tokenParsed.preferred_username);
                        //console.debug(keycloak.token);
                        // do what needs to be done if sign in was successful, for example store an access token
                        if (redirect != '') {
                            window.location.replace(redirect);
                        }
                        console.debug('We are good, authenticated!');
                    } else {
                        SessionService.logout();
                        let parts = window.location.href.split('/');
                        if (parts.length > 3 && parts[3] != '') {
                            window.location.replace('/');
                        }
                        console.error('We are not authenticated, not good');
                    }
                })
                .catch((err) => {
                    alert('Cannot login to the app:' + err);
                });

            keycloak.onTokenExpired = () => {
                console.debug('token expired', keycloak.token);
                keycloak
                    .updateToken(30)
                    .then((authenticated) => {
                        setAuthenticated(authenticated);
                        if (authenticated) {
                            SessionService.set(SessionService.token, keycloak.token);
                            console.debug('We are good, reauthenticated!');
                        } else {
                            SessionService.logout();
                            let parts = window.location.href.split('/');
                            if (parts.length > 3 && parts[3] != '') {
                                window.location.replace('/');
                            }
                            console.error('We are not reauthenticated, not good');
                        }
                    })
                    .catch((err) => {
                        alert('Cannot renew token: ' + err);
                    });
            };
        }
    }, []);

    return { keycloak, authenticated };
};

export default UseAuth;
