import React from 'react';
import { isMobile } from 'react-device-detect';

function Line() {
    if (isMobile) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <hr />
        );
    }

}

export default Line;
