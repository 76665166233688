import React from 'react';
import Translator from '../translations/Translator';
import UseAuth from './UseAuth';
import SessionService from '../service/SessionService';
import CookieConsent from 'react-cookie-consent';

function Header() {
    const { keycloak, authenticated } = UseAuth(false, '');
    const logout = () => {
        SessionService.logout();
        keycloak.logout();
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                    &nbsp;&nbsp;&nbsp;
                    <img src="https://ik.imagekit.io/mufleet/ui/icons/48.png" alt="48.png" />
                </a>
                <a className="navbar-brand" href="/">
                    <h1 className="fs-1">{process.env.REACT_APP_NAME}</h1>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                Device
                            </a>
                              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <li><a className="dropdown-item" href="/device">Device</a></li>
                                <li><a className="dropdown-item" href="/device-property">Device property</a></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><a className="dropdown-item" href="/device-management">Device management</a></li>
                              </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                Shipment
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <li><a className="dropdown-item" href="/shipment">Shipment</a></li>
                                <li><a className="dropdown-item" href="/shipment-new">New shipment</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/fleet">
                                <Translator value="i18n.fleet"/>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/profile">
                                <Translator value="i18n.profile"/>
                            </a>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <h5>{SessionService.get(SessionService.username)} at <a href="/profile">{SessionService.get(SessionService.currentGroupName)}</a></h5>&nbsp;&nbsp;&nbsp;
                    </div>
                    <button className="btn btn-danger" onClick={logout}>
                        <Translator value="i18n.logout" />
                    </button>
                </div>
            </nav>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
            <br />
        </div>
    );
}

export default Header;
