import React from 'react';

class DateService {
    public static toDate(unformattedDate: string) {
        //const timezoneOffset = new Date().getTimezoneOffset();
        //unformattedDate = unformattedDate.replaceAll('T', ' ');
        //unformattedDate = unformattedDate.split('.')[0];
        const date = new Date(unformattedDate);
        return date.toLocaleString();
    }
}

export default DateService;
