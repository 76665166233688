import React from 'react';
import PageProfile from './page/PageProfile';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page404 from './page/Page404';
import PageInviteUser from './archive/PageInviteUser';
import PageUsers from './archive/PageUsers';
import PageFleet from './page/PageFleet';
import PageView from './page/PageView';
import PageWelcome from './page/PageWelcome';
import PageDevice from './page/PageDevice';
import PageProperty from './page/PageProperty';
import PageDeviceManagement from './page/PageDeviceManagement';

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <div className="container-fluid">
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<PageWelcome />} />
                        <Route path="view" element={<PageView />} />
                        <Route path="device" element={<PageDevice />} />
                        <Route path="property" element={<PageProperty />} />
                        <Route path="device-management" element={<PageDeviceManagement />} />
                        <Route path="fleet" element={<PageFleet />} />
                        {/*<Route path="invite/supplier/reply" element={<PageInviteSupplierReply />} />
                        <Route path="invite/user/reply" element={<PageInviteUserReply />} />*/}
                        {/*<Route path="dashboard/products" element={<PageProducts />} />
                        <Route path="dashboard/products/form" element={<PageProductsForm />} />
                        <Route path="dashboard/sales" element={<PageSales />} />
                        <Route path="dashboard/rules" element={<PageRules />} />
                        <Route path="dashboard/rules/form" element={<PageRulesForm />} />
                        <Route path="dashboard/invitations/sent" element={<PageInvitationsSent />} />
                        <Route path="dashboard/invitations/received" element={<PageInvitationsReceived />} />
                        <Route path="dashboard/company" element={<PageCompany />} />
                        <Route path="dashboard/sales/form" element={<PageSalesForm />} />
                        <Route path="dashboard/sales/print" element={<PageSalesPrint />} />*/}
                        {/*<Route path="dashboard/retailers/products" element={<PageRetailerProducts />} />
                        <Route path="dashboard/invite/supplier" element={<PageInviteSupplier />} />*/}
                        <Route path="invite/user" element={<PageInviteUser />} />
                        {/*<Route path="dashboard/suppliers" element={<PageSuppliers />} />
                        <Route path="dashboard/retailers" element={<PageRetailers />} />*/}
                        <Route path="profile" element={<PageProfile />} />
                        <Route path="*" element={<Page404 />} />
                        {/*<Route path="print" element={<PagePrint />} />*/}
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
