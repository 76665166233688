import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import Required from '../shared/Required';
import ButtonDelete from './button/ButtonDelete';
import Response from '../model/Response';
import ButtonNavBlue from './button/ButtonNavBlue';
import RowFleet from './row/RowFleet';
import TableDevice from './table/TableDevice';
import TableProperty from './table/TableProperty';
import UseAuth from '../shared/UseAuth';
import SessionService from '../service/SessionService';

function PageProperty() {
    document.title = 'Property - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<any>();

    useLayoutEffect(() => {
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/metadata/key?type=DEVICE';
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    console.debug('data:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div>
                <Header />
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">{response && <TableProperty data={response.keys} group={response.currentGroup} />}</div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageProperty;
