import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import Response from '../model/Response';
import UseAuth from '../shared/UseAuth';
import SessionService from '../service/SessionService';
import TableDevice from './table/TableDevice';

function PageDevice() {
    document.title = 'Device - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<any>();

    useLayoutEffect(() => {
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/device';
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '?group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    SessionService.set(SessionService.currentGroup, res.currentGroup);
                    SessionService.set(SessionService.currentGroupName, res.currentGroupName);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div>
                <Header />
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">{response && <TableDevice data={response.uuids} group={response.currentGroup} />}</div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageDevice;
