import React from 'react';

class SessionService {
    public static token = 'token';
    public static username = 'user';
    public static groups = 'groups';
    public static currentGroup = 'currentGroup';
    public static currentGroupName = 'currentGroupName';

    public static set(key: string, value: any) {
        // @ts-ignore
        localStorage.setItem(key, value);
    }

    public static logout() {
        localStorage.clear();
    }

    public static get(key: string) {
        return localStorage.getItem(key)||'';
    }

    public static getArray(key: string) {
        const value = localStorage.getItem(key)||"";
        return value.split(",");
    }
}

export default SessionService;
