import React from 'react';
import UserDTO from '../../archive/model/UserDTO';
import RowUser from '../../archive/RowUser';
import Translator from '../../translations/Translator';
import RowFleet from '../row/RowFleet';
import { Shipment } from '../../model/Shipment';
import RowShipment from '../row/RowShipment';

function TableShipment(props: { data: Array<Shipment> }) {
    // @ts-ignore
    return (
        <table className="table table-dark table-sm table-striped table-responsive-md">
            <thead>
                <tr>
                    <th scope="col">id</th>
                    <th scope="col">createdAt</th>
                    <th scope="col">status</th>
                    <th scope="col">device</th>
                    <th scope="col">pickup</th>
                    <th scope="col">destination</th>
                    <th scope="col">pickupTime</th>
                    <th scope="col">destinationTime</th>
                    <th scope="col">actions</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item) => (
                    <RowShipment row={item} />
                ))}
            </tbody>
        </table>
    );
}

export default TableShipment;
