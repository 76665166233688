import React, { Suspense, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

function ClassMapContainer(props: { fleet: any; mapConfig: any }) {
    const customIcon = new L.Icon({
        iconSize: new L.Point(20, 20),
        iconUrl: 'https://ik.imagekit.io/mufleet/ui/truck-grey.ico'
    });

    const customIconLate = new L.Icon({
        iconSize: new L.Point(20, 20),
        iconUrl: 'https://ik.imagekit.io/mufleet/ui/truck-red.ico'
    });
    const readings = Object.keys(props.fleet).map((key) => {
        const record = props.fleet[key];
        if (record.status == 'NOT_TRACKING') {
            return <Marker position={[record.latitude, record.longitude]} icon={customIconLate} title={record.id} />;
        } else {
            return <Marker position={[record.latitude, record.longitude]} icon={customIcon} title={record.id} />;
        }
    });
    return (
        <MapContainer style={{ height: '500px', width: '100%' }} center={[props.mapConfig.lat, props.mapConfig.lon]} zoom={props.mapConfig.zoom} scrollWheelZoom={true}>
            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {readings}
        </MapContainer>
    );
}

export default ClassMapContainer;
