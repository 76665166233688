import React from 'react';
import QueryStringService from '../../service/QueryStringService';

function PageItem(props: { number: number; link: string }) {
    //let pagelink = props.link + props.number;

    const queryParams = new URLSearchParams(window.location.search);
    let page: string | null = queryParams.get('page');
    if (page == null) {
        page = '1';
    }

    const goTo = () => {
        QueryStringService.goTo(props.link, String(props.number));
    };

    if (parseInt(page) == props.number) {
        return (
            <a>
                <button className="btn btn-dark">{props.number}</button>
            </a>
        );
    } else {
        return (
            <a onClick={goTo}>
                <button className="btn btn-secondary">{props.number}</button>
            </a>
        );
    }
}

export default PageItem;
