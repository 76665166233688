import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import Response from '../model/Response';
import UseAuth from '../shared/UseAuth';
import SessionService from '../service/SessionService';
import TableDevice from './table/TableDevice';
import TableShipment from './table/TableShipment';
import { Shipment } from '../model/Shipment';

function PageShipment() {
    document.title = 'Shipment - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<Array<Shipment>>();

    useLayoutEffect(() => {
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/shipment';
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '?group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then(async (res) => {
                if (res.status > 399) {
                     var result = await res.json();
                     alert(result.message);
                    // @ts-ignore
                } else {
                    result = await res.json()
                    // @ts-ignore
                    setResponse(result);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    function handleSubmit() {
        window.location.replace('/shipment-new');
    }

    // @ts-ignore
        return (
            <Suspense
                fallback={
                    <p>
                        <Translator value="i18n.loading" />
                    </p>
                }
            >
                <div>
                    <Header />
                    <div className='row'>
                        <div className='col-md-1'>
                        </div>
                        <div className='col-md-10'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p>A shipment allows you to share the position of your trucks with external actors</p>
                                    <p>It can be beneficial in the following cases:</p>
                                    <ul>
                                        <li>You want to let your customers know where the truck is at any point of time</li>
                                        <li>You are a carrier and are subcontracting for other carriers</li>
                                    </ul>
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p>Your shipment will be trackable by your customers or subcontractors at <a href="https://mufleet.com/track" target="_blank">https://mufleet.com/track</a> </p>
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p>Click on the button below to create a shipment 😉</p>
                                    <button className="btn btn-success" onClick={handleSubmit}>
                                        Create a new shipment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">{response &&
                            <TableShipment data={response} />}</div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </Suspense>
        );

}

export default PageShipment;
