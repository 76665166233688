import React, { Suspense, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../../translations/Translator';
import { io } from 'socket.io-client';
import ClassMapContainer from './ClassMapContainer';
import QueryStringService from '../../service/QueryStringService';
import { isMobile } from 'react-device-detect';

// @ts-ignore
function ClassFleetMap(props: { group; mapConfig; mapContent }) {
    document.title = 'Fleet Map - mufleet';

    const [content, setContent] = useState(props.mapContent);
    const [fetch, setFetch] = useState(false);
    // @ts-ignore
    const socket = io(process.env.REACT_APP_UI_BACKEND, { autoConnect: false });
    const fetchFleet = async () => {
        let filter = QueryStringService.getString('filter');
        let key = null;
        let value = null;
        if (filter != null) {
            let parts = filter.split('=');
            key = parts[0];
            value = parts[1];
        }
        let cacheKey = 'fleet|' + props.group;
        if (key != null && value != null) {
            cacheKey = cacheKey + '|key|' + key + '|value|' + value;
        }
        if (fetch) {
            console.debug('Fetching already');
            return;
        }
        socket.on('fleet', (arg) => {
            setContent(arg);
            console.debug(arg);
        });
        console.debug('Listening on key ' + cacheKey);
        socket.emit('fleetRequest', cacheKey);
        console.debug('Emitted key ' + cacheKey);
        setFetch(true);
    };

    function delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    useEffect(() => {
        /*if (!socket.connected) {
            socket.connect();
        }
        if (!fetch) {
            //display
            fetchFleet();
            console.debug('Fetch fleet');
        }*/
        if (isMobile) {
            alert('Please use a computer for a better map experience');
        }
    }, [content]);

    // @ts-ignore
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3 row">{content && <ClassMapContainer fleet={content} mapConfig={props.mapConfig} />}</div>
                </div>
            </div>
        </Suspense>
    );
}

export default ClassFleetMap;
