import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import ClassMultiStepForm from './class/ClassMultiStepForm';
import UseAuth from '../shared/UseAuth';

function PageNewShipment() {
    document.title = 'New shipment - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');
    const [response, setResponse] = useState<any>();

    useLayoutEffect(() => {
        init();
    }, []);

    // @ts-ignore
    // @ts-ignore
    const init = () => {
    };

    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div>
                <Header />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <ClassMultiStepForm />
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageNewShipment;
