import React from 'react';

class QueryStringService {
    public static getString(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = queryParams.get(key);
        if (res != null) {
            console.debug('Found key ' + key + ' and value ' + res);
        }
        return res;
    }

    public static getStringOrFail(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = this.getString(key);
        if (res == null) {
            window.location.replace('/no-key');
        }
        return res;
    }

    public static getNumber(key: string) {
        const queryParams = new URLSearchParams(window.location.search);
        let res: string | null = queryParams.get(key);
        if (res == null) {
            res = '0';
        } else {
            let value = parseInt(res);
            value = value - 1;
            res = value + '';
        }
        return res;
    }

    public static goTo(key: string, value: string) {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has(key)) {
            queryParams.delete(key);
        }
        queryParams.append(key, value);
        // @ts-ignore
        window.location.replace(window.location.href.split('?')[0] + '?' + queryParams);
    }
}

export default QueryStringService;
