import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { control } from 'leaflet';
import Translator from '../translations/Translator';
import Header from '../shared/Header';
import Required from '../shared/Required';
import ButtonDelete from './button/ButtonDelete';
import Response from '../model/Response';
import ButtonNavBlue from './button/ButtonNavBlue';
import RowFleet from './row/RowFleet';
import UseAuth from '../shared/UseAuth';
import SessionService from '../service/SessionService';
import RowDevice from './row/RowDevice';
import TableDeviceManagement from './table/TableDeviceManagement';
import QueryStringService from '../service/QueryStringService';
import queryStringService from '../service/QueryStringService';

function PageDeviceManagement() {
    document.title = 'Device management - mufleet';

    const { keycloak, authenticated } = UseAuth(true, '');

    const [selected, setSelected] = useState<string>('');
    const [response, setResponse] = useState<any>();
    const [subResponse, setSubResponse] = useState<any>();

    useLayoutEffect(() => {
        init();
    }, []);

    const handleSelected = (event: { target: { value: any } }) => {
        if (event.target.value != '') {
            QueryStringService.goTo('id', event.target.value);
        }
    };

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/device';
        console.debug(query);
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '?group=' + SessionService.get(SessionService.currentGroup);
        }
        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    console.debug('data1:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
            });

        let id = queryStringService.getString('id');
        if (id != null) {
            initId(id);
        }
    };

    const initId = (id: string) => {
        setSelected(id);
        var query = process.env.REACT_APP_API_URL + '/v1/metadata?id=' + id + '&type=DEVICE';
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res);
                } else {
                    setSubResponse(res);
                    console.debug('data2:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // @ts-ignore
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div>
                <Header />
                <br />
                <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <select className='form-control form-select-lg' value={selected} onChange={handleSelected}>
                            <option value={''}>Click to select a device</option>
                            {response && response.uuids.map((item: string) => <option>{item}</option>)}
                        </select>
                        <br />
                        {subResponse && (
                            <TableDeviceManagement id={subResponse.metadataDomain.metadataId}
                                                   group={subResponse.currentGroup}
                                                   data={subResponse.metadataDomain.data}></TableDeviceManagement>
                        )}
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageDeviceManagement;
