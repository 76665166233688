import React, { useState } from 'react';
import SessionService from '../../service/SessionService';
import RowProperty from '../row/RowProperty';

function TableProperty(props: { data: Array<string>; group: string }) {
    const [newData, setNewData] = useState<string>();
    const [response, setResponse] = useState<any>();

    const handleData = (event: { target: { value: any } }) => {
        setNewData(event.target.value);
    };
    const add = () => {
        if(newData == null || newData == '' || newData == undefined){
            alert('The property value is required');
            return;
        }
        // @ts-ignore
        var query = process.env.REACT_APP_API_URL + '/v1/metadata/key?base64key=' + btoa(newData) + '&type=DEVICE';
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);

        fetch(query, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => {
                alert('An error happened, please try again');
            });
    };
    if (props.data.length === 0) {
        return (
            <div>
                <div className="input-group">
                    <input className="form-control" type={'text'} onChange={handleData} placeholder="Add a property here"></input>
                    <button className="btn btn-secondary" onClick={add}>
                        Add
                    </button>
                </div>
                <br />
                <div className="row">
                    <p>You have not added any property yet, go ahead 😉</p>
                </div>
                <br />
                <div className="row">
                    <p>Properties represent any information which might help identify a device : </p>
                    <ul>
                        <li>Phone number</li>
                        <li>License plate</li>
                        <li>Driver name</li>
                    </ul>
                </div>
                <div className="row">
                    <p>You are free to input any information you want.</p>
                </div>
            </div>
        );
    } else {
        // @ts-ignore
        return (
            <div>
                <div className="input-group">
                    <input className="form-control" type={'text'} onChange={handleData} placeholder="Add a property here"></input>
                    <button className="btn btn-secondary" onClick={add}>
                        Add
                    </button>
                </div>
                <br />
                <table className="table table-dark table-striped">
                    <thead>
                        <tr>
                            <th className="col-md-5">Name</th>
                            <th className="col-md-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((item) => (
                            <RowProperty row={item} />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableProperty;
