import React from 'react';
import DateService from '../../service/DateService';

function RowFleet(props: { row: any }) {
    var url = '/vehicle?id=' + props.row.id;
    return (
        <tr>
            <td>
                <a href={'/view?id=' + props.row.id}>{props.row.id}</a>
            </td>
            <td>{props.row.status}</td>
            <td>{props.row.address}</td>
            <td>{props.row.latitude}</td>
            <td>{props.row.longitude}</td>
            <td>{DateService.toDate(props.row.timestamp)}</td>
        </tr>
    );
}

export default RowFleet;
