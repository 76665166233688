import React, { useEffect, useLayoutEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder';
import L from 'leaflet';

const SearchControl = ({ onSearch }: { onSearch: (location: { lat: number; lng: number; name: string | null }) => void }) => {
    const map = useMap();

    useEffect(() => {
        const geocoder = L.Control.geocoder({
            defaultMarkGeocode: true
        }).on('markgeocode', (e: any) => {
            const { center, name } = e.geocode;

            // Zoom to the selected location
            map.setView(center, 16);

            // Add a marker at the searched location
            L.marker(center)
                .addTo(map)
                .bindPopup(name ?? 'Unknown')
                .openPopup();

            // Send the coordinates and name back to the parent
            onSearch({ lat: center.lat, lng: center.lng, name });
        });

        geocoder.addTo(map);

        /*// Find the search input field and focus it to "expand" on load
        const searchContainer = geocoder.getContainer(); // Get the DOM element for the control
        if (searchContainer) {
            const searchInput = searchContainer.querySelector("input");
            if (searchInput) {
                searchInput.focus(); // Focus the input programmatically
            }
        }*/

        return () => {
            map.removeControl(geocoder);
        };
    }, [map, onSearch]);

    return null;
};

type SearchedLocation = {
    lat: number;
    lng: number;
    name: string | null;
};

type ClassMapWithSearchProps = {
    onLocationChange: (location: SearchedLocation | null) => void; // Callback prop to inform the parent
};


const ClassMapWithSearch = ({ onLocationChange }: ClassMapWithSearchProps) => {
    const [searchedLocation, setSearchedLocation] = useState<SearchedLocation | null>(null);

    const handleSearch = (location: { lat: number; lng: number; name: string | null }) => {
        setSearchedLocation(location);
        onLocationChange(location);
        console.log('Searched Location:', location); // Access the searched coordinates here
    };

    return (
        <div>
            <MapContainer center={[9.622532, 24.653344]} zoom={3} style={{ height: '300px', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="© OpenStreetMap contributors" />
                <SearchControl onSearch={handleSearch} />
            </MapContainer>
            {searchedLocation && (
                <div>
                    <p>Location: {searchedLocation.name || 'Unknown'}</p>
                    <div className="row text-center">
                        <div className="col-md-6">
                            Latitude <br/><input type="number" defaultValue={searchedLocation.lat}/>
                        </div>
                        <div className="col-md-6">
                            Longitude <br/><input type="number" defaultValue={searchedLocation.lng}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClassMapWithSearch;
