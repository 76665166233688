import React, { Suspense, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';

function ClassInformation(props: { device: any }) {
    // @ts-ignore
    const rows = Object.keys(props.device.data).map((key) => {
        return (
            <tr>
                <td>{key}</td>
                <td>{props.device.data[key]}</td>
            </tr>
        );
    });
    return (
        <div className="mb-3 row">
            <table>
                <tr>
                    <td>id</td>
                    <td>{props.device.id}</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>{props.device.status}</td>
                </tr>
                {rows}
            </table>
        </div>
    );
}

export default ClassInformation;
