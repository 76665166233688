import React from 'react';
import DateService from '../../service/DateService';
import { Shipment } from '../../model/Shipment';
import ButtonDelete from '../button/ButtonDelete';

function RowShipment(props: { row: Shipment }) {
    var createdAt = DateService.toUIDate(props.row.createdTime);
    var pickupPeriod = DateService.toUIDate(props.row.pickupTimeMin) + ' - ' + DateService.toUIDate(props.row.pickupTimeMax);
    var destinationPeriod = DateService.toUIDate(props.row.destinationTimeMin) + ' - ' + DateService.toUIDate(props.row.destinationTimeMax);

    return (
        <tr>
            <td>
                {props.row.readableId}
            </td>
            <td>{createdAt}</td>
            <td>{props.row.status}</td>
            <td>{props.row.deviceId}</td>
            <td>{props.row.pickup}</td>
            <td>{props.row.destination}</td>
            <td>{pickupPeriod}</td>
            <td>{destinationPeriod}</td>
            <td><ButtonDelete id={props.row.id} type="shipment"/></td>
        </tr>
    );
}

export default RowShipment;
