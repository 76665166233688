import React from 'react';
import ButtonDelete from '../button/ButtonDelete';

function RowFleet(props: { row: string }) {
    return (
        <tr>
            <td>
                <p className="fs-5">{props.row}</p>
            </td>
            <td>
                <ButtonDelete id={props.row} type="device" />
            </td>
        </tr>
    );
}

export default RowFleet;
