import React, { useLayoutEffect, useState } from 'react';
import ClassMapWithSearch from './ClassMapWithSearch';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import Translator from '../../translations/Translator';
import SessionService from '../../service/SessionService';
import QueryStringService from '../../service/QueryStringService';
import Response from '../../model/Response';
import queryStringService from '../../service/QueryStringService';
import DateService from '../../service/DateService';
import { Shipment } from '../../model/Shipment';

interface FormData {
    pickupTimeMin: string;
    pickupTimeMax: string;
    destinationTimeMin: string;
    destinationTimeMax: string;
}

// Main Multi-step Form Component
const ClassMultiStepForm: React.FC = () => {
    // State to keep track of the current step
    const [selected, setSelected] = useState<string>('');
    const [response, setResponse] = useState<any>();
    const [fromSearchedLocation, setFromSearchedLocation] = useState<{ lat: number; lng: number; name: string | null } | null>(
        null
    );
    const [toSearchedLocation, setToSearchedLocation] = useState<{ lat: number; lng: number; name: string | null } | null>(
        null
    );

    useLayoutEffect(() => {
        init();
    }, []);

    const handleSelected = (event: { target: { value: any } }) => {
        setSelected(event.target.value);
    };

    // @ts-ignore
    // @ts-ignore
    const init = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/device';
        console.debug(query);
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '?group=' + SessionService.get(SessionService.currentGroup);
        }
        fetch(query, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    alert(res.error + Response.support + res.id);
                } else {
                    setResponse(res);
                    console.debug('data1:' + JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const post = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/shipment';
        console.debug(query);
        var shipment = new Shipment(
            {
                "groupId": SessionService.get(SessionService.currentGroup),
                "deviceId": selected,
                "pickupTimeMin": formData["pickupTimeMin"],
                "pickupTimeMax": formData["pickupTimeMax"],
                "destinationTimeMin": formData["destinationTimeMin"],
                "destinationTimeMax": formData["destinationTimeMax"],
                "pickup": fromSearchedLocation?.name,
                "destination": toSearchedLocation?.name,
                "pickupLat": fromSearchedLocation?.lat,
                "pickupLon": fromSearchedLocation?.lng,
                "destinationLat": toSearchedLocation?.lat,
                "destinationLon": toSearchedLocation?.lng
            });
        fetch(query, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(shipment)
        })
            .then(async (res) => {
                if (res.status > 399) {
                    var data = await res.json();
                    // @ts-ignore
                    alert(data.message)
                } else {
                    res.json()
                    setResponse(res);
                    alert('Shipment created!');
                    window.location.replace("/shipment");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    // State to store the form data
    const [formData, setFormData] = useState<FormData>({
        pickupTimeMin: DateService.toApiDate(new Date().toISOString()),
        pickupTimeMax: DateService.toApiDate(new Date().toISOString()),
        destinationTimeMin: DateService.toApiDate(new Date().toISOString()),
        destinationTimeMax: DateService.toApiDate(new Date().toISOString())
    });

    // Handle form field updates
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: DateService.toApiDate(e.target.value),
        });
    };

    // Simulate form submission
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        post();
    };

    const handleFromLocationChange = (location: { lat: number; lng: number; name: string | null } | null) => {
        setFromSearchedLocation(location); // Update the parent's state with the searched location
        console.log('Parent Updated with from Location:', location);
    };

    const handleToLocationChange = (location: { lat: number; lng: number; name: string | null } | null) => {
        setToSearchedLocation(location); // Update the parent's state with the searched location
        console.log('Parent Updated with to Location:', location);
    };

    const getCurrentDateTime = (): string => {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Ensures 2 digits
        const date = String(now.getDate()).padStart(2, '0'); // Ensures 2 digits
        const hours = String(now.getHours()).padStart(2, '0'); // Ensures 2 digits
        const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensures 2 digits

        return `${year}-${month}-${date}T${hours}:${minutes}`;
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div>
                        <select className='form-control form-select-lg' value={selected} onChange={handleSelected}>
                            <option value={''}>Choose which device will be tracking the shipment</option>
                            {response && response.uuids.map((item: string) => <option>{item}</option>)}
                        </select>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-md-6">
                    <div>
                        <h4 className="text-white">Pickup</h4>
                        <div className="mb-3">
                            <label className="form-label text-white">
                                Click on the top right lens in the map to search where the shipment goes from
                            </label>
                            <ClassMapWithSearch onLocationChange={handleFromLocationChange}/>
                            <br/>
                            <div className="row">
                                <div className="col-6 text-center">
                                    <label className="form-label text-white">
                                       Pickup earliest departure time
                                    </label>
                                    <br/>
                                    <input name="pickupTimeMin" onChange={handleInputChange}  defaultValue={getCurrentDateTime()} type="datetime-local" />
                                </div>
                                <div className="col-6 text-center">
                                    <label className="form-label text-white">
                                       Pickup latest departure time
                                    </label>
                                    <br/>
                                    <input name="pickupTimeMax" onChange={handleInputChange}   defaultValue={getCurrentDateTime()} type="datetime-local" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h4 className="text-white">Destination</h4>
                        <div className="mb-3">
                            <label className="form-label text-white">
                                Click on the top right lens in the map to search where the shipment goes to
                            </label>
                            <ClassMapWithSearch  onLocationChange={handleToLocationChange}/>
                            <br/>
                            <div className="row">
                                <div className="col-6 text-center">
                                    <label className="form-label text-white">
                                        Destination earliest departure time
                                    </label>
                                    <br/>
                                    <input name="destinationTimeMin" onChange={handleInputChange}   defaultValue={getCurrentDateTime()} type="datetime-local" />
                                </div>
                                <div className="col-6 text-center">
                                    <label className="form-label text-white">
                                        Destination latest departure time
                                    </label>
                                    <br/>
                                    <input name="destinationTimeMax" onChange={handleInputChange}   defaultValue={getCurrentDateTime()} type="datetime-local" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
                <div className="text-center">
                        <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                            Create a new shipment
                        </button>
                </div>
        </div>
    );
};

export default ClassMultiStepForm;