import React, {Suspense, useState, useEffect} from 'react';
import Header from '../shared/Header';

import ButtonDelete from './button/ButtonDelete';
import Translator from '../translations/Translator';
import Required from '../shared/Required';
import Line from '../shared/Line';
import SessionService from '../service/SessionService';
import UseAuth from "../shared/UseAuth";
import Response from "../model/Response";
import TranslationService from "../service/TranslationService";
import response from "../model/Response";

function PageProfile() {
    const { keycloak, authenticated } = UseAuth(true, '');

    const [company, setCompany] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleCompany = (event: { target: { value: any } }) => {
        setCompany(event.target.value);
    };

    const handleOldPassword = (event: { target: { value: any } }) => {
        setOldPassword(event.target.value);
    };

    const handleNewPassword = (event: { target: { value: any } }) => {
        setNewPassword(event.target.value);
    };

    const handleRepeatNewPassword = (event: { target: { value: any } }) => {
        setRepeatNewPassword(event.target.value);
    };

    const updateUser = () => {
        if(company.trim() == ''){
            alert('Company name is required');
            return;
        }
        console.debug('updating user');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/user';
        fetch(query, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                groups: [
                    {
                        key: SessionService.get(SessionService.currentGroup),
                        value: company
                    }
                ]
            })
        })
            .then((res) => {
                if(res.status>399){
                    alert('Duplicated company name');
                }else{
                    SessionService.set(SessionService.currentGroupName, company);
                    window.location.reload();
                }
            })
            .catch((error) => {
                alert(TranslationService.translate(Response.i18n['error-unknown']));
            });
    };


    const updatePassword = () => {
        if(newPassword != repeatNewPassword){
            alert('Passwords do not match');
            return;
        }

        console.debug('updating password');
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/user/password';

        fetch(query, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                oldPassword: oldPassword,
                newPassword: newPassword
            })
        })
            .then((res) => {
                if(res.status>399){
                    alert('The old password is not correct');
                }else{
                    alert(TranslationService.translate(Response.i18n['success-saved']));
                    window.location.reload();
                }
            })
            .catch((error) => {
                alert(TranslationService.translate(Response.i18n['error-unknown']));
            });
    };

    const deleteAccount = () => {
        var query;
        query = process.env.REACT_APP_API_URL + '/v1/user';

        fetch(query, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => {
                SessionService.logout();
                window.location.reload();
            })
            .catch((error) => {
                alert(TranslationService.translate(Response.i18n['error-unknown']));
            });
    };

    document.title = 'Profile - mufleet';
    // @ts-ignore
    return (
        <Suspense
            fallback={
                <p>
                    <Translator value="i18n.loading" />
                </p>
            }
        >
            <div className="row">
                <Header />
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="container-md">
                        <div>
                            <h5>
                                Profile management
                            </h5>
                            <Line/>
                            <div className="mb-3 row">
                                <label className="col-sm-3 form-label">
                                    <Translator value="i18n.username"/>
                                </label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" disabled
                                           defaultValue={SessionService.get(SessionService.username)}/>
                                </div>
                            </div>
                            {/* <div className="mb-3 row">
                                <label className="col-sm-3 form-label">Related companies</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" disabled value={email} />
                                </div>
                            </div> */}
                            <div className="mb-3 row">
                                <div className="col-sm-3">
                                    <Required/>
                                    <label className="col-sm-3 form-label">Company name</label>
                                </div>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" id={SessionService.get(SessionService.currentGroup)}
                                                               defaultValue={SessionService.get(SessionService.currentGroupName)}
                                    onChange={handleCompany}/>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <button onClick={updateUser} className="btn btn-secondary row">
                                    Save profile
                                </button>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <h5>
                                Change password
                            </h5>
                            <Line/>
                            <div className="mb-3 row">
                                <div className="col-sm-3">
                                    <Required/>
                                    <label className="col-sm-3 form-label">Old password</label>
                                </div>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handleOldPassword}/>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-3">
                                    <Required/>
                                    <label className="col-sm-3 form-label">New password</label>

                                </div>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handleNewPassword}/>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-3">
                                    <Required/>
                                    <label className="col-sm-3 form-label">Repeat new password</label>
                                </div>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" onChange={handleRepeatNewPassword}/>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <button className="btn btn-secondary row" onClick={updatePassword}>
                                    Save password
                                </button>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="row">
                            <h5>
                                <Translator value="i18n.dangerous.area"/>
                            </h5>
                            <Line/>
                            <div className="mb-3 row">
                                <label className="col-sm-11 form-label">
                                    <Translator value="i18n.delete.this.account"/> :
                                </label>
                                <div className="col-sm-1">
                                    <button className="btn btn-danger row" onClick={deleteAccount}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </Suspense>
    );
}

export default PageProfile;
