import React, { useState } from 'react';
import UserDTO from '../../archive/model/UserDTO';
import RowUser from '../../archive/RowUser';
import Translator from '../../translations/Translator';
import RowFleet from '../row/RowFleet';
import RowDevice from '../row/RowDevice';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';

function TableDevice(props: { data: Array<string>; group: string }) {
    const [data, setData] = useState<string>();
    const [response, setResponse] = useState<any>();

    const handleData = (event: { target: { value: any } }) => {
        setData(event.target.value);
    };
    const add = async () => {
        if(data == null || data == '' || data == undefined){
            alert('The device value is required');
            return;
        }
        var query = process.env.REACT_APP_API_URL + '/v1/device?id=' + data;
        if(SessionService.get(SessionService.currentGroup) != ''){
            query = query + '&group=' + SessionService.get(SessionService.currentGroup);
        }
        console.debug(query);
        let added = true;
        await fetch(query, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => {
                if (res.hasOwnProperty('error')) {
                    added = false;
                    alert(res);
                }
            })
            .catch((error) => {
                added = false;
                alert('An error happened, please try again ' + error);
            });
        if (added) {
            window.location.reload();
        }
    };
    if (props.data.length === 0) {
        return (
            <div>
                <div className='input-group'>
                    <input className='form-control' type={'text'} onChange={handleData}
                           placeholder='Add a device code here'></input>
                    <button className='btn btn-secondary' onClick={add}>
                        Add
                    </button>
                </div>
                <br />
                <div className='row'>
                     <p>You do not have any device code yet, start by downloading our driver app 😉</p>
                </div>
                <br />
                <div className='row'>
                    <a href='https://play.google.com/store/apps/details?id=com.mufleet' target='_blank'
                       rel='noreferrer'>
                        <img width='100%' src='https://ik.imagekit.io/mufleet/ui/img/google_play_64.png' alt='google play' />
                    </a>
                </div>
                <br />
                <div className='row'>
                     <p>Once the app is installed on your device, you will get a code that you can paste here</p>
                </div>
            </div>
        );
    } else {
        // @ts-ignore
        return (
            <div>
                <div className='input-group'>
                    <input className='form-control' type={'text'} onChange={handleData}
                           placeholder='Add a device code here'></input>
                    <button className='btn btn-secondary' onClick={add}>
                        Add
                    </button>
                </div>
                <br />
                <table className='table table-dark table-striped'>
                    <thead>
                        <tr>
                            <th className='col-md-5'>Code</th>
                            <th className='col-md-1'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((item) => (
                            <RowDevice row={item} />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableDevice;
