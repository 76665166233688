import React, { useState } from 'react';
import ButtonDelete from '../button/ButtonDelete';
import Translator from '../../translations/Translator';
import SessionService from '../../service/SessionService';
import Response from '../../model/Response';

function RowProperty(props: { row: string }) {
    const handleClick = () => {
        var query = process.env.REACT_APP_API_URL + '/v1/metadata/key?base64key=' + btoa(props.row) + '&type=DEVICE';
        console.debug('url ' + query);
        fetch(query, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + SessionService.get(SessionService.token)
            }
        })
            .then((res) => {
                console.debug(res);
                if (res.hasOwnProperty('error')) {
                    alert(Response.i18n['error-cannot-delete']);
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <tr>
            <td>
                <p className="fs-5">{props.row}</p>
            </td>
            <td>
                <button className="btn btn-danger btn-sm" onClick={handleClick}>
                    <Translator value="i18n.delete" />
                </button>
            </td>
        </tr>
    );
}

export default RowProperty;
